import React from 'react'
import { graphql } from 'gatsby'
import { responsiveImage } from '../utils/helpers.js'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import ClientSearch from '../components/ClientSearch'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import get from 'lodash/get'

const SearchTemplate = props => {
  const { pageContext } = props
  const { contentData } = pageContext
  const { allData, options } = contentData
  const heroImage = get(props, 'data.heroImage')
  const hero = responsiveImage(heroImage.childImageSharp.fluid.srcSetWebp)
  const site = get(props, 'data.site')

  return (
    <Layout>
      <div id="sub-page">
        <SEO 
          title="UNICOM Digital search" 
          description="Struggling to find a particular web page or piece of content? Use the UNICOM Digital search to hone in on what you need." 
          url={site.siteMetadata.siteUrl + props.location.pathname} 
          image={site.siteMetadata.siteUrl + hero}
        />
        
        <Hero
          class="intro-40"
          image={hero}
          title="Search UNICOM Digital"
          subtitle={null}
          type="search"
        />
      </div>

      <section className="bg-gray-light">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <ClientSearch content={allData} engine={options} />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
    </Layout>
  )
}
export default SearchTemplate

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    heroImage: file(name: { eq: "search" }) {
      childImageSharp {
        fluid(maxWidth: 2400, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
